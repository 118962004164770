import './Footer.css';
import React from "react"
import logo from '../Resources/logo.png'
import { Gafams } from '../Fonctions';

export default function Footer() {
  return (
    <footer id="footer" className="d-flex flex-wrap justify-content-between align-items-center xborder-top">
      <img src={logo} height="30px" alt='' />
      <ul className="nav col-md-7 col-xs-12 justify-content-end footerflex">
      <li>
          <div className="gafams">
            <Gafams/>
          </div>
        </li>
      </ul>
    </footer>
  )
}