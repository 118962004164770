import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo_jaune from "../Resources/pictogrammes/cddp-jaune.svg";
import navicon from "../Resources/pictogrammes/iconnav_white.min.svg";
import { Link } from "react-router-dom";

import { FaireunDonNoir } from "../Fonctions.js"


export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const toggleNavSmallScreen = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
      if (window.innerWidth > 500) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  const ScrollToDiv = () => {
    useEffect(() => {
        const observer = new MutationObserver((mutations, obs) => {
        const targetDiv = document.getElementById("scrollto_programme");
        setIsMobile(window.innerWidth <= 500);
        if (targetDiv && isMobile) {
          targetDiv.scrollIntoView({ behavior: "smooth" });
          obs.disconnect(); // Stopper l'observation après le scroll
        }
      });
  
      observer.observe(document.body, { childList: true, subtree: true });
  
      return () => observer.disconnect(); // Nettoyage en cas de démontage
    }, []);
}  

  return (
    <nav className="navbar-static-top">
      {(toggleMenu || largeur > 500) && (
        <ul className="list">
          <img className="logocddp" src={logo_jaune} alt="" />
          <Link to="/">
            <li className="items" onClick={toggleNavSmallScreen}><span className="arc">A</span>ccueil</li>
          </Link>
          <Link to="course">
            <li className="items" onClick={toggleNavSmallScreen}>Inscriptions</li>
          </Link>
          <Link to="recherche">
            <li className="items" onClick={toggleNavSmallScreen}>La <span className="arc">R</span>echerche</li>
          </Link>
          <Link to="partenaires">
            <li className="items" onClick={toggleNavSmallScreen}>Partenaires</li>
          </Link>
          <Link to="boutique">
            <li className="items" onClick={toggleNavSmallScreen}>Boutique</li>
          </Link>
          <Link to="contact">
            <li className="items" onClick={toggleNavSmallScreen}><span className="arc">C</span>ontacts</li>
          </Link>
          <FaireunDonNoir />
          <Link to="galerie">
            <li className="items" onClick={toggleNavSmallScreen}>Galerie</li>
          </Link>

          {/* <a rel="noopener noreferrer" target="_boutique" href="https://www.helloasso.com/associations/la-course-du-petit-prince-la-cdpp/boutiques/vente-produits-la-cdpp/widget">
            <li className="items">Boutique</li>
          </a> */}
        </ul>
      )}
      <img onClick={toggleNavSmallScreen} id="navicon" src={navicon} alt="" />
      <ScrollToDiv></ScrollToDiv>
    </nav>
  );
}
