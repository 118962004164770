import React from 'react';
import parcoursbtn from "../../Resources/pictogrammes/parcours.svg"
import inscriptionbtn from "../../Resources/inscription.png"

const showmap = (btn) => {
    var imgparcours = document.getElementsByClassName("imgparcours");
    for (let x = 0; x < imgparcours.length; x++) {
        imgparcours[x].style.display = "none";
    }

    var parcours = btn.target.getAttribute("id");
    var imageparcours = document.getElementById("parcour" + parcours);
    if (imageparcours.getAttribute("display") === "block") {
        imageparcours.setAttribute("display", "none")
        imageparcours.style.display = "none";
    } else {
        imageparcours.setAttribute("display", "block")
        imageparcours.style.display = "block";
    }
};
function ShowParcoursGpx({ id, value }) {
    // iframe
    if ( value.gpx ) {
        return (
            <>
           <iframe id={`parcour${id}`} key={id} title={value.distance} src={`${value.gpx}?iframe&bornes&sens&showArrivee`} allowfullscreen className="imgparcours iframe-gpx" loading="lazy" scrolling="no" style={{ display: 'none' }}></iframe>
            </>
        );
    } else {
        return (
            <img id={`parcour${id}`} key={id} src={process.env.PUBLIC_URL + '/images/parcours/' + value.id + '.png'} alt={`Parcour ${id}`} className='imgparcours' style={{ display: 'none' }} />
        )
    }
}
function ImagesParcours({ parcours }) {
    return (
        <div id="imagesparcours">
            {Object.entries(parcours).map(([key, parcour]) => (
                <img id={`parcour${key}`} key={key} src={process.env.PUBLIC_URL + '/images/parcours/' + parcour.id + '.png'} alt={`Parcour ${key}`} className='imgparcours' style={{display: 'none'}}/>
            ))}
        </div>
    );
}

function BtnParcours0({ parcours, list }) {
    return (
        // <div className="row">
        // <table className="table parcours-line">
        <>
            {Object.entries(parcours).map(([key, value]) => (
                list.includes(key) && (
                    <tbody>
                        <tr key={key}>
                            <td className='horaire notmobile'><span className={value.new}>{value.horaire}</span></td>
                            <td className='titre mobile'>
                                <span className={value.new}>
                                    <span className='distance'>{value.horaire}</span>
                                </span>
                                <br></br>{value.title}
                                <span className='distance'>{value.distance}&nbsp;{value.unit}</span>
                            </td>
                            <td className='titre notmobile'>
                                <span>{value.title}</span>
                                <span className='distance'>{value.distance}&nbsp;{value.unit}</span>
                            </td>
                            <td className='tarif'>
                                <div className="col card-prog blue">
                                    <span className="tarif">{value.tarif}€</span>
                                </div>
                            </td>
                            <td className='tarif'>
                                <div className="col card-prog dark">
                                    <span className="tarifj">{value.tarifj}€</span>
                                </div>
                            </td>
                            <td className='btns'>
                                <div className='map'>
                                    <button className="btn btn-xs xbtn-light xhideinmobile" id={key} type="button" onClick={showmap}>
                                        <img id={key} src={parcoursbtn} alt="" title="Afficher le parcours"></img>
                                    </button>
                                    <button className="btn xbtn-xs sbtn-light" id={key} type="button">
                                        <a href={value.inscription} target='_inscription'>
                                            <img src={inscriptionbtn} alt="Inscription en ligne" title="Inscription en ligne"></img>
                                        </a>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5">
                                <ShowParcoursGpx id={key} value={value}></ShowParcoursGpx>
                            </td>
                        </tr>
                    </tbody>
                )
            ))}
        </>
    );
}

function BtnParcours({ parcours, list }) {
    return (
        <>
            {Object.entries(parcours).map(([key, value]) => (
                list.includes(key) && (
                    <div key={key}>
                    <div className='parcours-box'>
                        <div className='box1 titre'>
                            <div>
                                <span className='horaire'>{value.horaire}</span>
                                <span className={`${value.new}`}>{value.title}</span>
                            </div>
                            <span className='distance'>{value.distance}&nbsp;{value.unit}</span>
                        </div>
                        <div className='box2'>
                            <span className={`tarif tarif-${value.id}`}>{value.tarif}</span>
                            <span className={`tarifj tarifj-${value.id}`}>{value.tarifj}</span>
                            <div className={`map map-${value.id}`}>
                                <button className="btn btn-xs" id={key} type="button" onClick={showmap}>
                                    <img id={key} src={parcoursbtn} alt="" title="Afficher le parcours"></img>
                                </button>
                                <button className="btn btn-xs" id={key} type="button">
                                    <a href={value.inscription} target='_inscription'>
                                        <img src={inscriptionbtn} alt="Inscription en ligne" title="Inscription en ligne"></img>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ShowParcoursGpx id={key} value={value}></ShowParcoursGpx>                    
                </div>
                )
            ))}
        </>
    );
}

export { ImagesParcours, BtnParcours0, BtnParcours };
