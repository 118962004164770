import React from "react";
import "../../Course/css/Inscriptions.css";
import pdf from "../../Resources/pictogrammes/pdf.svg";
import protiming from "../../Resources/protiming.png";
import vars from "../../files/vars.json"
import { Titre } from "../../Fonctions";

export default function Inscriptions() {
  return (
    <div className="container" id="inscription">
      <Titre libelle="Inscriptions" color="blue" image="cddp" />

      <div className="row">
        <div className="col-sm-6">
          <div className="card-prog btns">
            <a target="_blank" rel="noopener noreferrer" href={vars.url_chrono}>
              <button type="button" className="col-12 btn xbtn-warning">
                <h1>Inscriptions en ligne</h1>
                <img id="protiming" src={protiming} alt="ProTiming Logo" />
              </button>
            </a>
          </div>
        </div>

        <div className="col">
          <div className="card-prog btns">
            <a href={process.env.PUBLIC_URL + vars.doc_reglement} download>
            {/* <a href={process.env.PUBLIC_URL + "/documents/reglement.pdf"} download> */}
              <button type="button" className="btn btn-xs xbtn-warning">
                <h1>Le règlement</h1>
                <img src={pdf} alt="PDF Icon" width="50px" height="50px" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
