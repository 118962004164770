import React from "react";
import banniererecherche from "../Resources/banniererecherche.png";
import "./Recherche.css";
import { FaireunDon, FctTitre, Titre, UseGTMPageView } from "../Fonctions";
import condor from "../Resources/rhucondor.png";
import imgnews from "../Resources/pictogrammes/news.png";
import imgarticle from "../Resources/pictogrammes/article.png";
import vars from "../files/vars.json"
import recherche from "../files/recherche.json"

export default function Recherche() {
  const articles = recherche["articles"]
  const newsletter = recherche["newsletter"]

  const listArticles = articles.map((article, index) => (
    <div className="col" key={index}>
      <div className="card-prog">
        <button className="btn btn-article">
          <a href={article.link} target="_blank" rel="noopener noreferrer" aria-label={`Article ${index + 1}`}>
            <img src={imgarticle} alt={`Article ${index + 1}`} />
          </a>
          <h3 style={{textAlign: "center"}}>Article {index + 1}</h3>
        </button>
      </div>
    </div>
  ));

  const listNewsletters = newsletter.map((article, index) => (
    <div className="col" key={index}>
      <div className="card-prog">
        <button className="btn btn-newsletter">
          {article.type === "link" ? (
            <a href={article.link} target="_blank" rel="noopener noreferrer" aria-label={`Newsletter ${index + 1}`}>
              <img src={imgnews} alt={`Newsletter ${index + 1}`} />
            </a>
          ) : (
            <a href={process.env.PUBLIC_URL + "/documents/" + article.link} download>
              <img src={imgnews} alt={`Newsletter ${index + 1}`} />
            </a>
          )}
          <FctTitre article={article} index={index} />
        </button>
      </div>
    </div>
  ));

  return (
    <div id="Recherches" className="container-fluid">
      <FaireunDon />
      <UseGTMPageView />
      <div className="Recherche">
        <div className="row bandeau bleu bandeau-top">
          <img id="banniererecherche" src={banniererecherche} alt="Bannière Recherche" className="hideinmobile"/>
          <div className="col-sm-8 col-xs-10">
              <h3>Programme de recherche national RHU CONDOR sur les sarcomes (Institut Bergonié)</h3>
              <div style={{textAlign:"center"}}>
                <a href={vars.url_condor} target="_blank" rel="noopener noreferrer" aria-label="Site du programme CONDOR">
                  <img id="condor" src={condor} alt="Logo Condor" />
                </a>
              </div>
          </div>
        </div>

        <div className="container">
          <div className="lettre">
            <p className="lettre-titre">L'Année 2024</p>
            <p>
              Le programme <b>RHU CONDOR</b> de l'Institut Bergonié a enregistré en 2024 des avancées significatives dans la recherche sur les sarcomes, contribuant à une meilleure compréhension et prise en charge de ces tumeurs rares. Voici les principaux points :
            </p>

            <p className="lettre-titre">Analyse approfondie des échantillons</p>
            <p>Plus de 1800 échantillons de sarcomes ont été analysés, permettant des progrès dans la compréhension des mécanismes biologiques et génétiques de ces cancers, essentiels pour développer des traitements ciblés et efficaces.</p>

            <p className="lettre-titre">Préparation d’un essai clinique innovant</p>
            <p>Un essai clinique de phase II a été préparé, centré sur les sarcomes des tissus mous.</p>
            <p>Ce dernier combine plusieurs molécules thérapeutiques dans une approche novatrice visant à optimiser les traitements et améliorer la qualité de vie des patients.</p>

            <p className="lettre-titre">Organisation d’un symposium scientifique</p>
            <p>La 2ᵉ édition du symposium sur les sarcomes a favorisé les échanges entre chercheurs, cliniciens et experts, stimulant collaborations et initiatives interdisciplinaires pour accélérer les progrès scientifiques.</p>

            <p className="lettre-titre">
              Ces efforts renforcent la position de CONDOR en tant qu’acteur clé dans la recherche sur les sarcomes, avec l’objectif d’offrir de nouvelles perspectives aux patients et à leurs familles.
            </p>
          </div>
        </div>

        <div className="container">
          <Titre libelle="Les Newsletters du RHU CONDOR" color="blue" image="condor" />
          <div className="row">{listNewsletters}</div>

          <Titre libelle="Le programme RHU CONDOR" color="blue" image="condor" />
          <div className="row">{listArticles}</div>
        </div>
      </div>
    </div>
  );
}
