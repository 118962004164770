import './Home.css';
import React from "react";
import affiche from '../Resources/affiche.png';
import petitprince from '../Resources/pictogrammes/cdpp-jaune.svg';
import sarcomes from '../Resources/sarcome.png';
import deontologie from '../Resources/deontologie.png';
import Fondateurs from "./Fondateurs.js";
import condor from "../Resources/rhucondor.png";
import vars from "../files/vars.json"
import { FaireunDon, col_image,col_large, col_small, UseGTMPageView } from "../Fonctions.js";

export default function Home() {
    return (
        <div className="App">
        <div className="container">
            <FaireunDon />
            <UseGTMPageView />
        
            <section className="bandeau bleu bandeau-top">
                <div className='row'>
                    <div className={`col-xs-12 ${col_large}`}>
                        <div>
                            <h1>Édition 2025</h1>
                            <h2>Venez nous rejoindre pour aider la recherche contre les sarcomes</h2>
                            <p>
                                Que vous soyez compétiteur, amateur, adulte, enfant, coureur, marcheur, participant, spectateur ou bénévole,
                                nous vous invitons à venir courir et marcher pour aider la recherche contre les sarcomes.
                            </p>
                        </div>
                        <div style={{justifyItems: "center"}}>
                            <h1>Le 29 MAI 2025</h1>
                            <h2>au BOURGAILH - PESSAC, 33600</h2>
                        </div>
                    </div>
                    <div className={`col-xs-12 img ${col_small}`}>
                        <img id="affiche" className="rounded shadow" src={affiche} alt="Affiche de l'événement" />
                    </div>
                </div>
            </section>
            
            {/* Section Course du Petit Prince */}
            <section className="bandeau blanc">
                <div className='row'>
                    <div className={`col-xs-12 ${col_image}`}>
                        <img id="img_petitprince" className="img-fluid" src={petitprince} alt='Logo Petit Prince' />
                    </div>
                    <div className={`col-xs-12 ${col_large}`}>
                        <h1>La Course du Petit Prince</h1>
                        <p>
                            Créée en 2022, la course du Petit Prince (CdPP) est un événement caritatif organisé dans la forêt du Bourgailh, à Pessac (33).
                        </p>
                        <p>
                            Cet événement propose des marches et des courses pédestres enfants (6-13 ans) et adultes sur un circuit 100% nature.
                        </p>
                        <p>
                            Les dons des partenaires et les inscriptions payantes sont reversés intégralement au :
                        </p>
                        <h2>
                            Programme de recherche national RHU CONDOR sur les sarcomes (Institut Bergonié)
                        </h2>
                            <a target="_blank" rel="noopener noreferrer" href={vars.url_condor}>
                                <img id="condor" className="img-fluid mt-2" src={condor} alt="Logo Condor" />
                            </a>
                    </div>
                </div>
            </section>
            
            {/* Section Sarcomes */}
            <section className="bandeau bleu">
                <div className='row'>
                    <div className={`col-xs-12 ${col_large}`}>
                        <h1>Notre cause autour des sarcomes</h1>
                        <p>Les sarcomes sont des tumeurs rares représentant 15% des cancers de l’enfant et des jeunes adultes.</p>
                        <p>Ce sont des cancers agressifs touchant les tissus mous du corps, les viscères ou les os.</p>
                        <p>Ils ont bénéficié de peu d’avancées thérapeutiques au cours des dernières décennies.</p>
                    </div>
                    <div className={`col-xs-12 ${col_image}`}>
                        <img className="img-fluid" src={sarcomes} alt='Infographie sarcomes' />
                    </div>
                </div>
            </section>
            
            {/* Section Déontologie */}
            <section className="bandeau blanc" id='deontologie'>
                <div className="row">
                    <div className={`col-xs-12 ${col_image}`}>
                        <img className="img-fluid" src={deontologie} alt='Illustration déontologie' />
                    </div>
                    <div className={`col-xs-12 ${col_large}`}>
                        <h1>Déontologie</h1>
                        <ul>
                            <li>
                                <h2>Transparence</h2>
                                <ul>
                                    <li>
                                    <p>Nous fournissons un rapport détaillé des sommes collectées et reversées à la recherche.</p>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h2>Efficience</h2>
                                <ul>
                                    <li>
                                    <p>Les fonds sont directement affectés aux programmes de recherche sur les sarcomes.</p>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h2>Traçabilité</h2>
                                <ul>
                                    <li>
                                    <p>Nous informons sur les avancées et résultats obtenus grâce aux dons.</p>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            
            {/* Section Partenaires */}
            <section id="fondateurs">
                <h2 className="text-center my-4">Merci à nos partenaires</h2>
                <Fondateurs />
            </section>
        </div>
        </div>
    );
}
