import React from "react"
import './Resultats.css'
import photos from '../Resources/pictogrammes/photos.svg'
import { FaireunDon, Titre } from "../Fonctions";
import {CarouselGoogle,Video} from "./carousel_google"

export default function Resultats() {

    function LienPhotosCarousel({ rep, link, titre, delai=5000 }) {
        return (
            <div className="carousel-google">
                <h2>
                    <span>{titre}</span>
                    <a href={link} target="googlephotos" className="btn btn-default">
                        <img src={photos} alt="googlephotos"/>
                    </a>
                </h2>
                <CarouselGoogle rep={rep} delai={delai}></CarouselGoogle>
            </div>
        )
    }

    function LienVideo({ file, link, titre, delai=5000 }) {
        return (
            // <div className="wcard-prog carousel_block video">
                <Video file={file}/>
            // </div>
        )
    }

    return (
        <div id="Resultats">
            <FaireunDon />
            {/* PRESENTATION */}
            <div className="container" id='home'>
                <div className="row bandeau bleu bandeau-top">
                    <div className="col-sm-12 col-xs-12">
                        <h1>L'édition 2024 est terminée.</h1>
                        <h2>Un grand merci à tous et à toutes.</h2>
                    </div>
                </div>

                <div className="carousels">
                    <Titre libelle="Les Résultats" color="blue" image="cddp" />
                    <div className="row">
                        <div className="col card-prog dark">
                            <h2>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.klikego.com/resultats/la-course-du-petit-prince-2024/1644528897784-3">Les enfants, le 15 et le 7 </a>
                            </h2>

                        </div>

                    </div>

                    <Titre libelle="Les Photos du village" color="blue" image="cddp" />

                    <div className="row">
                        <div className="carousel-container-google">
                            <LienPhotosCarousel titre="Le village #1" rep="village1" link="https://photos.app.goo.gl/7geQQHqNHrBXFGVZ9" delai="3000"></LienPhotosCarousel>
                            <LienPhotosCarousel titre="Le village #2" rep="village2" link="https://photos.app.goo.gl/3c6AQKhAAvgqxcuF7" delai="2000"></LienPhotosCarousel>
                            <LienPhotosCarousel titre="Le village #3" rep="village3" link="https://photos.app.goo.gl/efRHtpotPe3rRXkc8" delai="2500"></LienPhotosCarousel>
                        </div>
                    </div>

                    <Titre libelle="Les Photos de la course des enfants" color="blue" image="cddp" />
                    <div className="row">

                        <LienPhotosCarousel titre="Courses des enfants" rep="courseenfants" link="https://photos.app.goo.gl/ADL7N67MVRdzhD4Q6"></LienPhotosCarousel>
                        {/* <LienPhotos titre="Courses des enfants"  /> */}
                    </div>
                    <Titre libelle="Les Photos des trails" color="blue" image="cddp" />
                    <div className="row">
                        <LienPhotosCarousel rep="trails1" titre="Trails #1" link="https://photos.app.goo.gl/Km3LZqV59pGBNQrXA" />
                        <LienPhotosCarousel rep="trails2" titre="Trails #2" link="https://photos.app.goo.gl/hgNkewAcmtSaftnb6" delai="3000" />
                        <LienPhotosCarousel rep="arriveetrails" titre="Arrivée  des Trails" link="https://photos.app.goo.gl/tviXrMKxSfcyQ8Cy9" />
                    </div>

                    <Titre libelle="Les Photos des marches" color="blue" image="cddp" />
                    <div className="row">
                        <LienPhotosCarousel rep="marcheurs1" titre="Marcheurs #1" link="https://photos.app.goo.gl/jo44fLQ1ACtD1VzXA" />
                        <LienPhotosCarousel rep="marcheurs2" titre="Marcheurs #2" link="https://photos.app.goo.gl/27zJtfdqLc8CJ3wTA" delai="2000" />
                    </div>

                    <Titre libelle="Les Photos suite" color="blue" image="cddp" />
                    <div className="row">
                        <LienPhotosCarousel rep="foret" titre="En forêt" link="https://photos.app.goo.gl/s1GALN3MEyKhNR2g7" />
                        <LienPhotosCarousel rep="concert" titre="Le Concert" link="https://photos.app.goo.gl/2AWyisR163RTgCNWA" />
                    </div>

                    <Titre libelle="Les vidéos" color="blue" image="cddp" />
                    <div className="row">
                        <div className="videos">
                            <LienVideo file="film1.mp4" link="https://youtu.be/cSDko4yy48g" />
                            <LienVideo file="film2.mp4" link="https://youtu.be/3mF0j-JFLbI?si=znuXmeX-22b9nmYs" />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}