import React from "react";
import "../../Course/css/Inscriptions.css";
import { Titre } from "../../Fonctions";

export default function Services() {
  return (
    <div className="container" id="inscription">
      <Titre libelle="Services gratuits" color="blue" image="cddp" />

      <div className="row">
        <div className="col">
          <div className="bandeau dark">
          <div className="testgrid">
            <h3>
              <p>Nous mettons à disposition des participants aux trails les services gratuits suivants :</p>
              <ul>
                <li>Une consigne abritée et surveillée sur le village pour les sacs (1 par personne).</li>
                <li>Un stand kiné à l’arrivée.</li>
                <li>Sur le 17 kms. 2 postes de ravitaillement intermédiaires</li>
                <ul>
                  <li>au 6ème km</li>
                  <li>au 12ème km</li>
                  <li>à l’arrivée</li>
                </ul>
                <li>Sur le 11 kms. 1 poste de ravitaillement intermédiaire</li>
                <ul>
                  <li>au 7ème km</li>
                  <li>à l’arrivée</li>
                </ul>
                <li>Des postes de ravitaillement séparés pour les enfants et les marcheurs à l’arrivée sur le village.</li>
                <li>
                  À l’arrivée des courses enfants, nous mettons en place un sas sécurisé afin que les parents puissent récupérer leurs enfants en toute  sécurité.
                </li>
              </ul>
            </h3>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
