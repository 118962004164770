import React from "react"
// import React, { useEffect } from "react"
import "../Course/css/Course.css";
// import { useLocation } from "react-router-dom";
import Deplacements from "./sections/Deplacements.js";
import Programme from "./sections/Programme.js";
import Animations from "./sections/Animations.js";
import Recompenses from "./sections/Recompenses.js";
import Inscriptions from "./sections/Inscriptions.js";
import Services from "./sections/Services.js";
import petitprince from "../Resources/pictogrammes/cdpp-jaune.svg";
import { FaireunDon, col_large, col_small, UseGTMPageView } from "../Fonctions.js";

export default function Course() {


  return (
    <div id="Course">
      <FaireunDon />
      <div className="container">
        <div className="row bloc bandeau bleu bandeau-top">
          <div className={`col-xs-12 img ${col_small}`}>
            <img className="img-bandeau" src={petitprince} alt="Petit Prince logo" />
          </div>
          <div className={`col-xs-12 ${col_large}`}>
            <div className="testgrid">
              <h1>La Course Du Petit Prince</h1>
              <p>
                La course du Petit Prince (La CdPP) est un événement caritatif organisé dans la forêt du Bourgailh, 
                160 av. de Beutre, 33600 Pessac en Gironde (33).
              </p>
              <p>
                L’événement comporte des marches et des courses pédestres pour enfants (6-13 ans) et adultes, 
                sur un circuit 100% nature entre la forêt du Bourgailh, les lacs de Cap de Bos et Romainville, et 
                le bois des sources du Peugue.
              </p>
              <p>
                Organisé les jeudis de l’Ascension, le parcours est ouvert et adapté à tous les amateurs souhaitant 
                avant tout prendre du plaisir dans une activité physique et/ou sportive pour une cause caritative.
              </p>
              <p>
                Le village départ/arrivée est installé au cœur du Bourgailh, au pied du belvédère. Vous y trouverez 
                des stands de restauration, un concert sur le théâtre de nature, ainsi que des stands partenaires.
              </p>
              <p><i>Aucun bénéfice n’est conservé par La CdPP.</i></p>
            </div>
          </div>
        </div>
      </div>
      
      <Programme />
      <Inscriptions />
      <Services />
      <Animations />
      <Recompenses />
      <Deplacements />
      <UseGTMPageView />
    </div>
  );
}
