import React from "react"
import '../Partenaire/Partenaire.css'
import iconepart from '../Resources/Partenaires.png'
import PartnersGallery from "./PartnersGallery.js"
import { FaireunDon, col_large, col_small } from "../Fonctions.js";

export default function Course() {
    return (
        <div id="Partenaires" className="Partenaire">
            <FaireunDon />
            <div className="container-fluid" id='pres-part'>
                <div className='row bandeau bleu bandeau-top'>
                    <div className={`col-xs-12 ${col_large}`}>
                        <h1>Ce projet est propulsé par ceux qui nous aident et nous soutiennent.</h1>
                    </div>
                    <div className={`col-xs-12 img ${col_small} hideinmobile`}>
                        <img src={iconepart} id="imgpart" alt='' />
                    </div>
                </div>
            </div>

            <div className="card-part">
                <div className="row">
                    <div className="col">
                        <PartnersGallery />
                    </div>
                </div>
            </div>
        </div>
    )
}