import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import cdpp_blanc from "./Resources/pictogrammes/cdpp_blanc.svg";
import logotbm from "./Resources/tbm.svg";
import condor from "./Resources/condor.svg";
import faireundonnoir from "./Resources/pictogrammes/faireundonnoir.svg";
import lists from "./files/carousels.json";
import vars from "./files/vars.json";
import instagram from "./Resources/pictogrammes/instagram.svg";
import facebook from "./Resources/pictogrammes/facebook.svg";
import youtube from "./Resources/pictogrammes/youtube.svg";

const col_small = "col-sm-4";
const col_large = "col-sm-8";
const col_image = "col-sm-4 img hideinmobile";

function FctTitre({ article }) {
    return article.titre ? (
        <div>
            <h3 style={{ textAlign: "center" }}>{article.titre}</h3>
        </div>
    ) : null;
}

function Titre({ libelle, color, image }) {
    const imagesMap = {
        cddp: cdpp_blanc,
        logotbm: logotbm,
        default: condor
    };

    return (
        <div className="row rowtitle">
            <div className={`title col card-prog ${color}`}>
                <h1>
                    {libelle}
                    {image === "carousel" ? <Carousel /> : <img className="title-img" src={imagesMap[image] || imagesMap.default} alt="" />}
                </h1>
            </div>
        </div>
    );
}

function FaireunDon() {
    return (
        <button className="btn discret faireundonmobile">
            <a target="_blank" rel="noopener noreferrer" href={vars.url_faireundon}>
                <img src={faireundonnoir} width="100px" alt="Faire un don" className="faireundon" />
            </a>
        </button>
    );
}

function FaireunDonNoir() {
    return (
        <button className="btn discret inmenu">
            <a target="_blank" rel="noopener noreferrer" href={vars.url_faireundon}>
                <img id="faireundon" src={faireundonnoir} alt="Faire un don" className="faireundon" />
            </a>
        </button>
    );
}

function LienPhotos({ titre, link }) {
    return (
        <div className="col card-prog">
            <h2>
                <a target="_blank" rel="noopener noreferrer" href={link}>{titre}</a>
            </h2>
        </div>
    );
}

function LienVideo({ image, link }) {
    return (
        <div className="col card-prog white">
            <a target="_blank" rel="noopener noreferrer" href={link}>
                <img className="LienVideo" src={image} alt="Vidéo" />
            </a>
        </div>
    );
}

function Carousel() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = lists["podium"];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [images.length]);

    return (
        <div id="carouselPodium">
            <img src={process.env.PUBLIC_URL + images[currentImageIndex]} alt={`carouselPodium ${currentImageIndex + 1}`} className="carousel-image" />
        </div>
    );
}

function UseGTMPageView() {
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "page_view",
            pagePath: location.pathname,
            pageTitle: document.title,
        });
    }, [location]);
}

function Gafams() {
    return (
        <>
            <a target="_blank" rel="noopener noreferrer" href={vars.gafams.instagram}>
                <img src={instagram} alt="Instagram" width="40px" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={vars.gafams.facebook}>
                <img src={facebook} alt="Facebook" width="40px" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={vars.gafams.youtube}>
                <img src={youtube} alt="YouTube" width="40px" />
            </a>
        </>
    );
}

export { Titre, FaireunDon, LienPhotos, LienVideo, FctTitre, FaireunDonNoir, UseGTMPageView, Gafams, col_large, col_small, col_image };
