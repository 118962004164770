import React from "react";
// import "../Course/css/Programme_suite.css";
// import Carousel from "./Carousel.js";
import { Titre } from "../../Fonctions";

export default function Animations() {
  return (
    <>
      <div className="container" id="animations">
        <Titre libelle="Animations" color="blue" image="cddp" />
        <div className="row">
          <div className="col">
          <div className="bandeau dark">
            <div className="testgrid">
              <h2>De 10h30 à 12h30</h2>
              <h3>
                <ul>
                  <li>
                    Animations pour les enfants sur le village
                    <ul>
                      <li>Atelier "Réalisation des tableaux"</li>
                      <li>Stand maquillage enfants</li>
                    </ul>
                  </li>
                </ul>
              </h3>
              <h2>De 12h à 16h</h2>
              <h3>
                <ul>
                  <li>Concerts</li>
                </ul>
              </h3>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
