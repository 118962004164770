import React, {useState, useRef, useEffect} from "react";
import "../../Course/css/Deplacement.css";
import logotbm from "../../Resources/tbm.svg";
// import CarouselIti from "./CarouselIti.js";
import { Titre } from "../../Fonctions.js";
import deplacements_file from "../../files/deplacements.json"


const TransportCard = ({ title, direction, direction2, link, heightRef, height }) => (
  <div className="col-md-4 col-12">
    <div className="card-prog yellow" ref={heightRef} style={{ height }}>
      <h2 className="title-transportcard">
        {title}
        <button className="btn xbtn-light">
          <a target="_blank" rel="noopener noreferrer" href={link}>
            <img src={logotbm} alt={`${title} logo`} />
          </a>
        </button>
      </h2>
      <h3>{direction}<br />{direction2}</h3>
    </div>
  </div>
);

export default function Deplacements() {
  const firstButtonRef = useRef(null);
  const [buttonHeight, setButtonHeight] = useState("auto");

  useEffect(() => {
    if (firstButtonRef.current) {
      setButtonHeight(`${firstButtonRef.current.offsetHeight}px`);
    }
  }, []);
  
  const boutons = deplacements_file.boutons;

  return (
    <div className="container">
      <Titre libelle="Allez-y avec le réseau" color="blue" image="logotbm" />
      <div className="row">
        <div className="col">
          <div className="bandeau dark" >
          <div className="testgrid">
            <i>
              <p>La réduction de notre empreinte carbone est une préoccupation majeure.</p>
              <p>Nous favorisons les transports en commun avec notre partenaire TBM, ainsi que les moyens de transport "propres" (vélo, trottinette, roller, etc.) et le covoiturage.
              </p>
            </i>
            <h3>
              <ul>
                <li>Un parking vélo/trottinette/roller est disponible à proximité du départ.</li>
                <li>Le parking voiture du Bourgailh est limité à environ 200 véhicules.</li>
              </ul>
            </h3>
          </div>
          </div>
        </div>
      </div>

    <div className="row">
      {boutons.map((btn, index) => (
        <TransportCard 
          key={index}
          {...btn}
          heightRef={index === 0 ? firstButtonRef : null}
          height={buttonHeight}
        />
      ))}
    </div>
    </div>
  );
}
