import React from "react"
import '../../Course/css/Course.css'
import { BtnParcours } from './Parcours.js';
import { Titre,FaireunDon } from "../../Fonctions.js"
import parcours from '../../files/parcours.json';

export default function Programme() {
     
    return (
        <>
            <div id="scrollto_programme"></div>
            <div className="container" id="programme">
                <Titre libelle="Programme de la journée" don={FaireunDon} color="blue" image="cddp" />
                <div className="row">
                    <div className="col">
                        <div style={{padding: "10px 0"}}>
                            <BtnParcours parcours={parcours} list={["p5"]} />
                            <BtnParcours parcours={parcours} list={["p11"]} />
                            <BtnParcours parcours={parcours} list={["p1"]} />
                            <BtnParcours parcours={parcours} list={["p2"]} />
                            <BtnParcours parcours={parcours} list={["p17"]} />
                            <BtnParcours parcours={parcours} list={["p7"]} />
                            <BtnParcours parcours={parcours} list={["6x2"]} />                       
                            <BtnParcours parcours={parcours} list={["legende"]} />                       
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}