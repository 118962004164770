import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


function importAll(r) {
    return r.keys().map(fileName => ({
      fileName,
      fileContent: r(fileName)
    }));
  }

const imagesAll = {
    "village1":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/village1', false, /\.(png|jpe?g|svg)$/)),
    "village2":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/village2', false, /\.(png|jpe?g|svg)$/)),
    "village3":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/village3', false, /\.(png|jpe?g|svg)$/)),
    "courseenfants":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/courseenfants', false, /\.(png|jpe?g|svg)$/)),
    "trails1":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/trails1', false, /\.(png|jpe?g|svg)$/)),
    "trails2":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/trails2', false, /\.(png|jpe?g|svg)$/)),
    "arriveetrails":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/arriveetrails', false, /\.(png|jpe?g|svg)$/)),
    "marcheurs1":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/marcheurs1', false, /\.(png|jpe?g|svg)$/)),
    "marcheurs2":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/marcheurs2', false, /\.(png|jpe?g|svg)$/)),
    "foret":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/foret', false, /\.(png|jpe?g|svg)$/)),
    "concert":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/concert', false, /\.(png|jpe?g|svg)$/))
}

// const videosAll = {
//     "video1":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/video1', false, /\.(mp4)$/)),
//     "video2":importAll(require.context(process.env.PUBLIC_URL + '/public/images/carousels/2024/video1', false, /\.(mp4)$/))
// }

function CarouselGoogle({ rep = "village1", delai=3000 }) {

  const images = imagesAll[rep]

  return (
        // modules={[Autoplay, Navigation, Pagination]}
    <div className="w-full max-w-2xl mx-auto p-2">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        // autoplay={{ delay: 1000000, disableOnInteraction: false }}
        autoplay={{ delay: delai, disableOnInteraction: false }}
        // pagination={{ clickable: false }}
        // navigation
        loop
        className="rounded-xl shadow-lg"
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img
              src={img.fileContent}
              alt={`Slide ${index}`}
              className="carousel-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

  
function Video({file}) {

    return (
      <div className="xvideo mx-auto p-4">
        <video        
            src={process.env.PUBLIC_URL + '/videos/' + file} alt={file}
            className="w-full video object-cover rounded-xl"
            autoPlay
            loop
            muted
            controls
        />
      </div>
    );
  };
  
  
export {CarouselGoogle, Video}
