import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../Contact/Contact.css'
import prince from "../Resources/pictogrammes/cdpp-jaune.svg";
import { Titre } from "../Fonctions"
import { FaireunDon, Gafams } from "../Fonctions.js";
import vars from "../files/vars.json"



function Contact() {
  const [from_name, setNom] = useState('');
  const [user_email, setuser_email] = useState('');
  const [reply_to, setreply_to] = useState('');
  const [message, setMessage] = useState('');
  function handleChange(e) {
    if (e.target.name === 'from_name') {
      setNom(e.target.value);
    } else if (e.target.name === 'reply_to') {
      setreply_to(e.target.value);
      setuser_email(e.target.value);
    } else if (e.target.name === 'message') {
      setMessage(e.target.value);
    }
  }
  function hideMailSend() {
    var mailSend = document.getElementById('mailSend')
    mailSend.className = "col-12 btn btn-success hidden";
  }
  function handleSubmit(e) {
    e.preventDefault();
    // var x = emailjs.send("service_m8z81at","template_dvqs8rs",{
    //   to_name: "JF",
    //   from_name: "BARRE eric",
    //   message: "TEST",
    //   reply_to: "eduelfe@gmail.com",
    //   }, 'jUbWTcta1Vk6b4dnA');

    var mailSend = document.getElementById('mailSend')
    mailSend.className = "col-12 btn btn-success";
    // const myTimeout = setTimeout(hideMailSend, 5000);
    // emailjs.sendForm('service_m8z81at', 'template_dvqs8rs', e.target, 'jUbWTcta1Vk6b4dnA')
    emailjs.sendForm(vars.emailjs.serviceID, vars.emailjs.templateID, e.target, vars.emailjs.userID)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    setNom('');
    setreply_to('');
    setMessage('');
  }

  return (
    <div id="Contacts" className="Contact">
      <FaireunDon></FaireunDon>
      <div className='container'>
        <Titre libelle="Nous Contacter" color="blue" image="cddp" />
        {/* <div className="row">
          <div className='col-md-4 '>
          </div>
          <div className='col-md-4 gafams'>
          </div>

        </div> */}
        <div className="row">
          <div className='col-md-4 cddp_email'>
            <div>
              <Gafams/>
            </div>
            <img src={prince} id="princecontact" alt="" className='hideinmobile'/>
          </div>
          <div className='col-md-8'>
            <form onSubmit={handleSubmit} id="formMail">
              <div className="col-12">
                <label for="from_name" className="form-label"><b>Nom Prénom</b></label>
                <input type="text" name="from_name" className="form-control" placeholder="Nom Prénom" value={from_name} onChange={handleChange} />
              </div>
              <div className="col-12">
                <label for="email" className="form-label"><b>Email</b></label>
                <input type="email" name="reply_to" className="form-control" placeholder="Email" value={reply_to} onChange={handleChange} />
              </div>
              <div className="col-12">
                <label for="message" className="form-label">
                  <b>Votre Message</b>
                </label>
                <textarea name="message" className="form-control" placeholder="Message" rows="8" value={message} onChange={handleChange} />
              </div>
              <div className="col-12">
                <div className='email-send'>
                  <div>
                    <button id="buttontext" type="submit" className="btn btn-xs btn-primary">Envoyer</button>
                  </div>
                  <div>
                    <span>Nous vous répondrons dans les plus brefs délais par mail.<br/>
                      <span className="text-small">Votre adresse de messagerie ne sera pas publiée.</span>
                    </span>
                  </div>
                </div>
              </div>
              <div id="mailSend" className='col-12 btn btn-success hidden'>Le mail a bien été envoyé</div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

          export default Contact;
