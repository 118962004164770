import React from 'react';
import hrefs_json from '../files/partenaires.json'

function PartnersGallery() {
  // Utilise require.context pour importer dynamiquement les images à partir du répertoire "images"
  const importAll = (r) => {
    let images = {};
    r.keys().forEach((filePath) => {
      const key = filePath.replace("./", "").split(".")[0];
      const ext = filePath.split(".").pop();
      images[key] = { content: r(filePath), ext };
    });
    return images;
  };

  const images = importAll(require.context(process.env.PUBLIC_URL + '/public/images/partenaires/', false, /\.(png|jpe?g|svg)$/));

  const shuffleJson = (data) => {
    return Object.fromEntries(
        Object.entries(data)
            .sort(() => Math.random() - 0.5) // Mélange aléatoire
    );
};

const hrefs = shuffleJson(hrefs_json);

  return (
    <div className="image-gallery">
      {Object.entries(hrefs).map(([key, url]) => {
        const imagesKeys = Object.keys(images);
        let town = '';
        if (key.indexOf("_") > -1) {
          town = key.substring(key.indexOf("_") + 1)
          if (town.indexOf(".") > -1) {
            town = town.replace(".", " ")
          }
  
        }
        if (imagesKeys.includes(key)) {
          if (town) {
            return (
              <div className="partner">
                <a target="_blank" rel="noopener noreferrer" key={key} href={url} className='ville_partenaire'>
                  <img src={images[key].content} alt="" className='partenaire' />
                </a>
                <span className="town">{town}</span>
              </div>
            );
          } else {          
            return (
              <div key={key} className="partner">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img src={images[key].content} alt={`Galery${key}`} className="partenaire" />
                </a>
              </div>
            )
          }
        } else {
          return (
            <div key={key} className="partner">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {key}<br></br>
                {url}
              </a>
            </div>
          )
        }
      })}
    </div>
  );


}

export default PartnersGallery;
