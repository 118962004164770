import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

import Navbar from './Navbar/Navbar.js';
import Home from './Home/Accueil.js';
import Course from './Course/Course.js';
import Recherche from './Recherche/Recherche.js';
import Partenaire from './Partenaire/Partenaire.js';
import Contact from './Contact/Contact.js';
import Footer from './Footer/Footer.js';
import R2024 from './Resultats/2024.js';
import Boutique from './Boutique.js';

const routes = [
  { path: "/", component: <Home /> },
  { path: "/course", component: <Course /> },
  { path: "/galerie", component: <R2024 /> },
  { path: "/recherche", component: <Recherche /> },
  { path: "/partenaires", component: <Partenaire /> },
  { path: "/contact", component: <Contact /> },
  { path: "/boutique", component: <Boutique /> }
];

function SwipeNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const currentIndex = routes.findIndex(route => route.path === location.pathname);
  
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < routes.length - 1) {
        navigate(routes[currentIndex + 1].path);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        navigate(routes[currentIndex - 1].path);
      }
    },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div {...handlers} className="swipe-container">
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
      </Routes>
    </div>
  );
}

function App() {
  function ThemeManager() {
    // const [searchParams] = useSearchParams();
    // const theme = searchParams.get('theme');
  
    useEffect(() => {
      // if (theme === 'dark') {
      //   document.body.classList.add('dark');
      // } else {
      //   document.body.classList.remove('dark');
      // }
      document.body.classList.add('dark');
    }, []); // Exécution uniquement lorsque le `theme` change
  
    return null;
  }
  
  return (
    <>
      <Helmet>
        <title>Course du Petit Prince - Course caritative familiale</title>
        <meta
          name="description"
          content="Rejoignez la Course du Petit Prince, un événement familial et caritatif pour soutenir une noble cause. Inscrivez-vous dès maintenant !"
        />
        <meta
          name="keywords"
          content="course à pied, course caritative, course enfants, événement sportif, soutien associatif, bordeaux, pessac"
        />
        <meta name="robots" content="index" />
        <link rel="icon" type="image/png" href="/images/favicon.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <BrowserRouter>
        <ThemeManager/>
        <Navbar />
        <SwipeNavigation />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
