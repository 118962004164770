import React from "react";
// import "../Course/css/Programme_suite.css";
import { Titre } from "../../Fonctions";

export default function Recompenses() {
  return (
    <div className="container" id="recompenses">
      <Titre libelle="Récompenses" color="blue" image="carousel" />
      <div className="row">
        <div className="col">
          <div className="bandeau dark">
          <div className="testgrid">
            <h3>
              <ul>
                <li>Un t-shirt technique lors du retrait des dossards sera remis aux 500 premiers inscrits sur le trail de 17 kms</li>
                <li>Une casquette « CdPP » sera remis lors du retrait des dossards aux inscrits sur le trail de 7 kms</li>
                <li>Médailles pour tous les enfants à l’arrivée</li>
                <li>Coupes et lots aux trois premiers/premières des courses adultes et enfants sur 2 kms</li>
              </ul>
            </h3>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
