import React from 'react';

function Fondateurs() {
  function importAll(r) {
    return r.keys().map(fileName => ({
      fileName,
      fileContent: r(fileName)
    }));
  }
  const images = importAll(require.context(process.env.PUBLIC_URL + '/public/images/fondateurs/', false, /\.(png|jpe?g|svg)$/));

  return (
    <div className="gallery">
      {images.map((image, index) => {
          return <img key={index} src={image.fileContent} alt="" className='fondateur allways-keep'/>;
      })}
    </div>
  );
}

export default Fondateurs;
